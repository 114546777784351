/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello.",
  title2: "Sherwin",
  logo_name: "SCFR",
  nickname: "Sherwin",
  full_name: "Sherwin Christopher F. Roxas",
  subTitle:
    "Full Stack Developer / Mobile Photographer",
  resumeLink:
    "https://drive.google.com/file/d/1AwBwn1towM0A40_bNgY3OKSYE5Od-w6A/view?usp=drive_link",
  mail: "mailto:sherwin.roxas@scfr-dev.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/MysticMaccc",
  linkedin: "https://www.linkedin.com/in/sherwin-christopher-roxas-031861289/",
  gmail: "sherwin.roxas@scfr-dev.com",
  gitlab: " ",
  facebook: " ",
  twitter: "https://twitter.com/Sher_DevOps",
  instagram: "https://www.instagram.com/molliee_s_shutter?igsh=MTM5cnpzd2V6NWQ3Ng%3D%3D&utm_source=qr",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using ReactJS",
        "⚡ Developing mobile applications using ReactJS and Ionic",
        "⚡ Backend development using Laravel",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        // {
        //   skillName: "Java",
        //   fontAwesomeClassname: "simple-icons:java",
        //   style: {
        //     color: "#f89820",
        //   },
        // },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "simple-icons:kotlin",
          style: {
            color: "#5c79df",
          },
        },
        // {
        //   skillName: "Dart",
        //   fontAwesomeClassname: "simple-icons:dart",
        //   style: {
        //     color: "#29B0EE",
        //   },
        // },
        // {
        //   skillName: "Go",
        //   fontAwesomeClassname: "simple-icons:go",
        //   style: {
        //     color: "#00A7D0",
        //   },
        // },
        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        // {
        //   skillName: "C#",
        //   fontAwesomeClassname: "simple-icons:csharp",
        //   style: {
        //     backgroundColor: "transparent",
        //     color: "#3776AB",
        //   },
        // },
        // {
        //   skillName: "C",
        //   fontAwesomeClassname: "simple-icons:c",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        // {
        //   skillName: "C++",
        //   fontAwesomeClassname: "simple-icons:cplusplus",
        //   style: {
        //     color: "#E94E32",
        //   },
        // },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#FFFFFF",
            backgroundColor: "#1D1D1D",
            borderRadius: "50%",
          },
        },
        // {
        //   skillName: "NodeJS",
        //   fontAwesomeClassname: "simple-icons:node-dot-js",
        //   style: {
        //     color: "#339933",
        //   },
        // },
        {
          skillName: "Laravel",
          fontAwesomeClassname: "simple-icons:laravel",
          style: {
            color: "#339933",
          },
        },
        // {
        //   skillName: "MongoDB",
        //   fontAwesomeClassname: "simple-icons:mongodb",
        //   style: {
        //     color: "#439743",
        //   },
        // },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        {
          skillName: "Android App Development",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#02569B",
        //   },
        // },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },

        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },

        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },

    // {
    //   title: "Cloud Infra-Architecture",
    //   fileName: "CloudInfraImg",
    //   skills: [
    //     "⚡ Certified AWS Developer Associate and Cloud Practioner",
    //     "⚡ Experience working on multiple cloud platforms",
    //     "⚡ Experience hosting and managing web apps on GCP and AWS",
    //     "⚡ Deploying deep learning models on cloud to use on mobile devices",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "GCP",
    //       fontAwesomeClassname: "simple-icons:googlecloud",
    //       style: {
    //         color: "#4285F4",
    //       },
    //     },
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     {
    //       skillName: "Netlify",
    //       fontAwesomeClassname: "simple-icons:netlify",
    //       style: {
    //         color: "#38AFBB",
    //       },
    //     },
    //     {
    //       skillName: "Heroku",
    //       fontAwesomeClassname: "simple-icons:heroku",
    //       style: {
    //         color: "#6863A6",
    //       },
    //     },
    //     {
    //       skillName: "Firebase",
    //       fontAwesomeClassname: "simple-icons:firebase",
    //       style: {
    //         color: "#FFCA28",
    //       },
    //     },
    //     {
    //       skillName: "MongoDB",
    //       fontAwesomeClassname: "simple-icons:mongodb",
    //       style: {
    //         color: "#47A248",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: "Data Science & AI",
    //   fileName: "DataScienceImg",
    //   skills: [
    //     "⚡ Experience of working with Computer Vision and NLP projects",
    //     "⚡ Certifications by deeplearning.ai and Stanford Online",
    //     "⚡ Experience with 10+ Projects",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Tensorflow",
    //       fontAwesomeClassname: "logos-tensorflow",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Keras",
    //       fontAwesomeClassname: "simple-icons:keras",
    //       style: {
    //         backgroundColor: "white",
    //         color: "#D00000",
    //       },
    //     },
    //     {
    //       skillName: "PyTorch",
    //       fontAwesomeClassname: "logos-pytorch",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "ion-logo-python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //     {
    //       skillName: "Pandas",
    //       fontAwesomeClassname: "simple-icons:pandas",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#6E6E6E",
    //       },
    //     },
    //   ],
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Lyceum of the Philippines University - Laguna",
      subtitle: "Master of Science in Information Technology",
      logo_path: "lpu.png",
      alt_name: "LPU",
      duration: "2019 - 2021",
      descriptions: [
        // "⚡ Studied Advanced Software Development Concepts, Data Management & Warehousing",
        // "⚡ Learn Advanced Topics in Cloud Computing, Cloud Architecture along with Serverless Computing",
        // "⚡ Worked on projects on courses like Mobile Computing and Web Technologies.",
      ],
      website_link: "https://lpulaguna.edu.ph",
    },
    {
      title: "Systems Technology Institute - Calamba",
      subtitle: "Bachelor of Science in Information Technology",
      logo_path: "sti.png",
      alt_name: "STI",
      duration: "2013 - 2018",
      descriptions: [
        // "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for ML/DL, Web Development, Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've learnt under my Computer Engineering course. ",
      ],
      website_link: "https://www.sti.edu/campuses-details.asp?campus_id=Q0FM",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "React JS",
      subtitle: "NYK-Fil Maritime E-Training, Inc.",
      logo_path: "aws.png",
      certificate_link: "https://drive.google.com/file/d/1xAT7xl8Af7QnCTMdM9p-d3lWciJXdVnn/view?usp=sharing",
      alt_name: "React JS",
      color_code: "#ffc475",
    },
    {
      title: "WordPress with Elementor",
      subtitle: "Udemy",
      logo_path: "aws.png",
      certificate_link: "https://drive.google.com/file/d/1J3m4Ziav6fz-queAfunfqL9EF1W6n1y-/view",
      alt_name: "WordPress",
      color_code: "#ffc475",
    },
    {
      title: "Bootstrap",
      subtitle: "Udemy",
      logo_path: "aws.png",
      certificate_link:
        "https://drive.google.com/file/d/1-Zpg6cj3j3l8buQW3TSxhc5N377WrhA2/view",
      alt_name: "Bootstrap",
      color_code: "#ffc475",
    },
    {
      title: "CSS",
      subtitle: "Udemy",
      logo_path: "google_logo.png",
      certificate_link: "https://drive.google.com/file/d/1VZCMJb9Fh_gZ8NtjO94fXURq1NkvWqpy/view",
      alt_name: "Udemy",
      color_code: "#ffc475",
    },
    {
      title: "WordPress",
      subtitle: "Udemy",
      logo_path: "google_logo.png",
      certificate_link: "https://drive.google.com/file/d/1ROeVqG5B-MRQzkM-JOyrIfAwLNcVEQHU/view",
      alt_name: "Udemy",
      color_code: "#1e70c1",
    },
    {
      title: "Fullstack Laravel + Vue JS",
      subtitle: "Udemy",
      logo_path: "google_logo.png",
      certificate_link: "https://drive.google.com/file/d/1lP6OyzSdfCRpFChBNvKu4Wqp1vfIVY1e/view",
      alt_name: "Udemy",
      color_code: "#ffbfae",
    },
    {
      title: "ChatGPT Master Course",
      subtitle: "Udemy",
      logo_path: "google_logo.png",
      certificate_link: "https://drive.google.com/file/d/19FQWnIS0Js3R1Z8T0IWAmKAQ-i3vZyS4/view",
      alt_name: "Udemy",
      color_code: "#b190b0",
    },
    {
      title: "Basic iOS Mobile Application Development",
      subtitle: "FIT Academy",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/19FQWnIS0Js3R1Z8T0IWAmKAQ-i3vZyS4/view",
      alt_name: "FIT Academy",
      color_code: "#47A048",
    },
    {
      title: "Laravel Framework",
      subtitle: "Inventive Media",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/19FQWnIS0Js3R1Z8T0IWAmKAQ-i3vZyS4/view",
      alt_name: "Inventive Media",
      color_code: "#F6B808",
    },
    {
      title: "Advance Android Application Development",
      subtitle: "FIT Academy",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1X8UWEs16iJq2OAb3IC_-Cc2uWjyTg16l/view",
      alt_name: "FIT Academy",
      color_code: "#2AAFED",
    },
    {
      title: "Basic Android Application Development",
      subtitle: "FIT Academy",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1X8UWEs16iJq2OAb3IC_-Cc2uWjyTg16l/view",
      alt_name: "FIT Academy",
      color_code: "#E2405F",
    },
    {
      title: "SCRUM Master Training",
      subtitle: "APEX Global",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1PKdYxlJqM-m9BbfdLsKbb2haL0oC0B_c/view",
      alt_name: "APEX Global",
      color_code: "#8C151599",
    },
    {
      title: "Outsystems Web Development",
      subtitle: "Transnational E-Business Solutions, Inc.",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/10DLg6nCSm7wxNUoVCmgAFWfwhYqIzRzk/view",
      alt_name: "Transnational E-Business Solutions, Inc.",
      color_code: "#7A7A7A",
    },
    {
      title: "Web Programming",
      subtitle: "UP IT Development Center",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1kEB8CHonYIkRI30Ck1RfQN3ZwqFUVOUm/view",
      alt_name: "UP IT Development Center",
      color_code: "#0C9D5899",
    },
    {
      title: "IOS App Development",
      subtitle: "UP IT Development Center",
      logo_path: "100.png",
      certificate_link: "https://drive.google.com/file/d/1T3fqyihP3_aa5bKoZrtZvwNl_Qesiq7h/view",
      alt_name: "UP IT Development Center",
      color_code: "#C5E2EE",
    },
    {
      title: "Career Service Professional (CSC)",
      subtitle: "Civil Service Commission",
      logo_path: "ino.png",
      certificate_link: "https://drive.google.com/file/d/1M_aSYoQyTbg80kCfutIrtBRkuxdzbChR/view",
      alt_name: "Civil Service Commission",
      color_code: "#fffbf3",
    },
  ],
};

// Experience Page
const experience = {
  title: "Work Experience",
  subtitle: "",
  description:
    "I'm currently the Senior Developer at NYK-Fil Maritime E-Training, Inc.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Senior Developer",
          company: "NYK-Fil Maritime E-Training, Inc.",
          company_url: "https://netiaccess.com",
          logo_path: "NETI.png",
          duration: "January 2018 - Ongoing",
          location: "Calamba City, Laguna, Philippines",
          description:
            "NYK-FIL Maritime E-Training, Inc. (NETI) is the training arm of NYK-Fil Ship Management, Inc. An ISO 9001:2015 certified company, it is engaged in maritime training using state-of-the-art equipment for marine officers and ratings (non-officers) and develops customized training courses designed to meet the specific requirements of Principals.",
          color: "#f10000",
        },
        // {
        //   title: "Full Stack Developer",
        //   company: "Quicko",
        //   company_url: "https://quicko.com/",
        //   logo_path: "quicko.jpg",
        //   duration: "June 2021 - October 2021",
        //   location: "Ahmedabad",
        //   description:
        //     "Quicko is an online tax planning, preparation & filing platform for individuals & businesses.",
        //   color: "#2962FF",
        // },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "Google Summer of Code",
    //       company: "Sugar Labs",
    //       company_url: "https://sugarlabs.org/",
    //       logo_path: "google_logo.png",
    //       duration: "May 2019 - Aug 2019",
    //       location: "Work From Home",
    //       description:
    //         "I worked on the Dashboard project which helps users track their activities while using Sugar OS. I also worked on making a Tamagotchi-like widget for Sugar's Home Screen",
    //       color: "#ee3c26",
    //     },
    //     {
    //       title: "Android App Developer Intern",
    //       company: "IAS4Sure",
    //       company_url: " ",
    //       logo_path: "buld.jpg",
    //       duration: "December 2019 - February 2020",
    //       location: "Work From Home",
    //       description:
    //         "Internship task was to make a native Android application. ",
    //       color: "#0071C5",
    //     },
    //     {
    //       title: "Web Developer",
    //       company: "Wrighter Writing Solutions",
    //       company_url: " ",
    //       logo_path: "wrighter.jpg",
    //       duration: "August 2020",
    //       location: "",
    //       description: "Develop a website using PHP and jQuery.",
    //       color: "#56A4D3",
    //     },
    //     {
    //       title: "Front-End Developer",
    //       company: "VJ TechServe",
    //       company_url: " ",
    //       logo_path: "vjt.png",
    //       duration: "September 2020",
    //       location: "",
    //       description: "Develop a portfolio website using ReactJS",
    //       color: "#800000",
    //     },
    //   ],
    // },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Google Summer of Code Mentor",
    //       company: "Sugar Labs / GSoC",
    //       company_url: "https://sugarlabs.org/",
    //       logo_path: "google_logo.png",
    //       duration: "Summer of 2017 / 18 / 20",
    //       location: " ",
    //       description:
    //         "Mentorship responsibilities were to help students plan the project, review issues and pull requests, ensure smooth progress and help them out when they are stuck.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Google Code-In Mentor",
    //       company: "Sugar Labs / GSoC",
    //       company_url: "https://sugarlabs.org/",
    //       logo_path: "google_logo.png",
    //       duration: "Winter of 2017 / 18 / 19",
    //       location: " ",
    //       description:
    //         "Day to day resposibilites of this mentorship was to help out children aged 13-17 get started with programming and open-source, review their work and approve them.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "Board Member at Codeuino",
    //       company: " ",
    //       company_url: " ",
    //       logo_path: "codeuino.jpg",
    //       duration: "2018 - 2019",
    //       location: " ",
    //       description: "Previous Board during early days of Codeuino",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "BluWings Club",
    //       company: " ",
    //       company_url: " ",
    //       logo_path: "b.png",
    //       duration: "2018 - On Going",
    //       location: " ",
    //       description:
    //         "Co-Founder and President of the programming club called BluWings. Have organized several workshops which introduced programming and related tools to freshers.",
    //       color: "#D83B01",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of technology tools (React JS, Laravel, Laravel-livewire, Tailwind CSS, Alpine JS). My best experience is to create business projects. Below are some of my projects. Note that not all of the mentioned projects are on hosting yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile.jpeg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try my best to write a blog about it.",
    link: "https://medium.com/@hrishipatel99",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      name: "NETI-OEX",
      url: "https://netiaccess.com",
      description: "NYK-Fil Maritime E-Training, Inc. Online Enrollment System",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "SpoonPH",
      url: "https://spoon.ph",
      description: "NYK-Fil Maritime E-Training, Inc. Fitness App",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "Meal Tracker",
      url: "https://meal-tracker.neti.com.ph",
      description: "NYK-Fil Maritime E-Training, Inc. Meal Management System",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "Vue JS",
          iconifyClass: "logos-vue",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "Tic Tac Toe",
      url: "https://tictactoe.scfr-dev.com",
      description: "My Sample React Project",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Quiz App",
      url: "https://quiz-app.scfr-dev.com",
      description: "My Sample React Project",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Place Picker",
      url: "https://place-picker.scfr-dev.com",
      description: "My Sample React Project",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Cart List",
      url: "https://cart-list.scfr-dev.com",
      description: "My Sample React Project",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Project Management",
      url: "https://project-management.scfr-dev.com",
      description: "My Sample React Project",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Investment Calculator",
      url: "https://investment-calculator.scfr-dev.com",
      description: "My Sample React Project",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "Timer App",
      url: "https://timer-app.scfr-dev.com",
      description: "My Sample React Project",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      name: "e-Portal",
      url: "#",
      description:
        "NYK-Fil Maritime E-Training, Inc. Telephone Directory",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "Vue JS",
          iconifyClass: "logos-vue",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "Bookshelf",
      url: "#",
      description: "NYK-Fil Maritime E-Training, Inc. Library System",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "Vue JS",
          iconifyClass: "logos-vue",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "ProcSys",
      url: "#",
      description: "NYK-Fil Maritime E-Training, Inc. Procurement System",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "Vue JS",
          iconifyClass: "logos-vue",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "WOMS",
      url: "#",
      description: "NYK-Fil Maritime E-Training, Inc. Work Order Management System",
      languages: [
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "Vue JS",
          iconifyClass: "logos-vue",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "IAMS",
      url: "#",
      description: "NYK-Fil Maritime E-Training, Inc. Inventory and Asset Management System",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
    {
      name: "DMS",
      url: "#",
      description: "NYK-Fil Maritime E-Training, Inc. Document Management System",
      languages: [
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Laravel",
          iconifyClass: "logos-laravel",
        },
        {
          name: "MySql",
          iconifyClass: "logos-mysql",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
